import styled from 'styled-components';
//const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Regular';
const RadioButtonGroup = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: ${(props) => (props.challengeLeaderBoard || props.billingCards) ? 'space-between' : 'center'};
  margin-right: ${(props) => props.challengeLeaderBoard ? '9vw' : '25px'};
  margin-bottom:${(props) => props.createSurvey ? '0px' : '10px'};
  float: ${(props) => (props.challengeLeaderBoard || props.billingCards) ? 'left' : 'none'};
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  @media (max-width: 1140px) and (min-width: 1001px) {
   margin-right: ${(props) => props.challengeLeaderBoard ? '3vw' : '25px'};
  }
  @media (max-width:1140px) {
    float: none;
  }
  &:last-child {
    margin-right: 0px;
  }
  @media (max-width: 520px) {
     margin-right: 0;
     margin-bottom: ${(props) => props.challengeLeaderBoard ? '0' : '12px'};
  }
  @media (max-width: 360px) {
     margin-bottom: 12px;
  }
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + label {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #67676A;
      display: inline-block;
      position: relative;
      padding: ${(props) => (props.challengeLeaderBoard || props.isSpouseDependent || props.billingCards || props.createSurvey) ? '0' : '0 0px'};
      margin-bottom: 0;
      cursor: pointer;
      cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
      vertical-align: bottom;
      color:  ${(props) => (props.challengeLeaderBoard || props.billingCards)? '#52525F' :props.challengeCreate?'#005c87':props.createSurvey ? '#005C87': props.userBiometric ? 'rgb(43,81,102)' : 'rgb(153,153,153)'};
      font-family: ${RegularFont};
      font-size: 16px;
      align-items: center;
      display: flex;  
      @media (max-width: 520px) {
      min-width: ${(props) => props.challengeLeaderBoard ? 'unset' : '150px'};
      text-align: left;
      }
      @media (max-width: 360px) {
      //min-width: 150px;
      font-size: 13px;

      }
      &:last-child {
      padding-right: 0;
      }
      &:before {
        content: '';
        border-radius: 100%;
        border:1px solid #007AB1;
        ${'' /* border: ${(props) => props.challengeLeaderBoard ? '2px solid #979797' : props.userBiometric ? '2px solid rgb(43,81,102)' : props.createSurvey ? "2px solid rgba(0, 92, 135, 0.60)" :'2px solid #005C8799'}; */}
        display: inline-block;
        width: 24px;
        height: 24px;
        position: relative;
        margin-right: 0.4em;
        vertical-align: top;
        cursor: pointer;
        cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
        text-align: center;
        ${'' /* transition: all 250ms ease; */}
      }
    }
    &:checked {
      + label {
      &:before {
        border-radius: 50%;
        background-image:url(https://ddf0gygzuiev4.cloudfront.net/images/newRadioCheck.svg);
        background-repeat:no-repeat;
        background-position:center;
        box-shadow:none;
        border:1px solid #005C87;
        ${'' /* background-color: ${(props) => (props.challengeLeaderBoard || props.billingCards)  ? 'rgba(253, 113, 117, 1)' : props.userBiometric ? 'rgb(43,81,102)' :  props.createSurvey ? '#85C0EA' : '#85C0EA'};
        box-shadow: ${(props) => (props.challengeLeaderBoard || props.billingCards) ? 'inset 0 0 0 3px #ededed' :  props.userBiometric ? 'inset 0 0 0 3px #fff' : 'inset 0 0 0 4px #fff'};
        border: ${(props) => (props.challengeLeaderBoard || props.billingCards) ? '2px solid rgba(156, 156, 156, 1)' : props.userBiometric ? '2px solid rgb(43,81,102)' : props.createSurvey ? "2px solid rgba(0, 92, 135, 0.60)"  : '2px solid #005C8799'} */}
      }
    }
  }
`;

export {RadioButtonGroup};